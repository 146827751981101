<template>
    <div>
        <b-row>
            <b-col md="3">
                ss<rekap></rekap>
            </b-col>
            <b-col md="9">
                <b-card>
                 ss   <tabel></tabel>
                </b-card>
            </b-col>
        </b-row> 
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue' 
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton, 

        }

    }
</script>

<style>

</style>